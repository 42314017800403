import dayjs from 'dayjs'
import { getRoomTypes, getConfig } from '@/api/priceSign'
// 横屏竖屏可选择背景
const defaultBgImageKeys = ['blue', 'orange', 'gray', 'black']
export default {
  /**
   * 获取酒店房型信息
   */
  async getRoomTypes(hotelId) {
    const useRoomTypes = []
    const data = await getRoomTypes(hotelId)
    if (data.code !== 0) {
      return useRoomTypes
    }
    // 原始房型列表
    const originRoomTypes = data.data
    for (let index = 0; index < originRoomTypes.length; index++) {
      const item = originRoomTypes[index]
      const useRoomType = this.getUseRoomTypeInfo(item)
      if (useRoomType != null) {
        useRoomTypes.push(useRoomType)
      }
    }
    return useRoomTypes
  },

  /**
   * 获取使用的房型信息
   */
  getUseRoomTypeInfo(roomType) {
    const roomTypeTodayInfo = roomType.prices[0]
    if (roomTypeTodayInfo.totalRoomCount <= 0) {
      return null
    }
    const newRoomType = {}
    newRoomType.id = roomType.id
    newRoomType.roomTypeName = roomType.roomTypeName
    newRoomType.price = roomTypeTodayInfo.price
    newRoomType.full = roomTypeTodayInfo.currentRoomCount === 0
    newRoomType.cover = roomType.imgNames ? roomType.imgNames.split('|')[0] : ''
    return newRoomType
  },

  /**
   * 初始化配置信息
   */
  getShowInfo(originConfigInfo, originRoomTypes, userLevelConf) {
    // 背景图
    this.initBackgroundImageInfo(originConfigInfo)
    // 一页显示多少个价格类型
    originConfigInfo.pageShowPriceTypeCount = 3
    // // 房型房价标题的颜色
    // originConfigInfo.roomTypeTitleBackgroundColor =
    //   originConfigInfo.roomTypeBackgroundColor +
    //   this.getTransparency(originConfigInfo.titleTransparency)
    // // 房型房价内容的颜色
    // originConfigInfo.roomTypeContentBackgroundColor =
    //   originConfigInfo.roomTypeBackgroundColor +
    //   this.getTransparency(originConfigInfo.contentTransparency)
    // 房型显示的价格
    const roomTypePrice = this.getRoomTypePrice(
      originRoomTypes,
      userLevelConf,
      originConfigInfo
    )
    // 每页显示几个房型 默认6个
    const pageShowCount = originConfigInfo.displayRows
    const roomTypes = roomTypePrice.roomTypes
    const roomTypePages = Array.from(
      { length: Math.ceil(roomTypes.length / pageShowCount) },
      (v, i) =>
        roomTypes.slice(i * pageShowCount, i * pageShowCount + pageShowCount)
    )
    roomTypePrice.roomTypePages = roomTypePages
    return {
      roomTypePrice,
      configInfo: originConfigInfo
    }
  },

  /**
   * 背景图
   */
  initBackgroundImageInfo(config) {
    // 判断原来配置的是默认图片还是自定义
    if (defaultBgImageKeys.includes(config.backgroundImageUrl)) {
      config.bgImageKey = config.backgroundImageUrl
      return
    }
    config.bgImageKey = 'custom'
  },

  /**
   * 获取透明度
   */
  getTransparency(value) {
    const decimal = 255
    const hexadecimal = Math.floor((decimal * (100 - value)) / 100).toString(16)
    return hexadecimal
  },

  /**
   * 获取房型显示的价格
   * 显示的价格类型
   * 房型列表
   * 价格列表
   */
  getRoomTypePrice(roomTypes, memberLevels, configInfo) {
    //  需要显示的价格类型
    // （0：系统价，1：会员最低价，2：0级会员，3：1级会员，4：2级会员，5：3级会员，6：4级会员，7：5级会员）
    const showPriceTypes = configInfo.showPrice.split(',')
    // 获取价格类型信息
    const getPriceTypeInfo = (priceType) => {
      // 门市价
      if (priceType === 0) {
        return {
          discount: 1,
          name: '门市价'
        }
      }
      // 最低价返回会员最高等级的折扣
      if (priceType === 1) {
        return {
          discount: memberLevels[memberLevels.length - 1].discount,
          name: '最低价'
        }
      }
      // 其余都是会员级别
      const levelIndex = priceType - 2

      // 如果配置了的级别关闭了，那么返回-1不显示
      if (levelIndex > memberLevels.length - 1) {
        return null
      }
      const memberLevel = memberLevels[levelIndex]
      return {
        discount: memberLevel.discount,
        name: memberLevel.levelName
      }
    }

    // 房型显示的价格
    const priceTypeInfoList = []
    for (let index = 0; index < showPriceTypes.length; index++) {
      const priceType = parseInt(showPriceTypes[index])
      const priceTypeInfo = getPriceTypeInfo(priceType)
      if (!priceTypeInfo) {
        continue
      }
      // 显示的房型价格
      const list = []
      for (let index = 0; index < roomTypes.length; index++) {
        const roomType = roomTypes[index]
        const price = Math.floor(roomType.price * priceTypeInfo.discount)
        list.push(price)
      }
      priceTypeInfo.type = priceType
      priceTypeInfo.prices = list
      priceTypeInfoList.push(priceTypeInfo)
    }

    const roomTypePrice = {
      roomTypes,
      priceTypeInfoList
    }
    return roomTypePrice
  },

  /**
   * 当前时间
   */
  getCurrentTime() {
    const date = new Date().getTime()
    const day = dayjs(date).format('MM月DD日')
    const time = dayjs(date).format('HH:mm')
    let dayOfWeek
    const num = dayjs(date).day()
    switch (num) {
      case 0:
        dayOfWeek = '星期天'
        break
      case 1:
        dayOfWeek = '星期一'
        break
      case 2:
        dayOfWeek = '星期二'
        break
      case 3:
        dayOfWeek = '星期三'
        break
      case 4:
        dayOfWeek = '星期四'
        break
      case 5:
        dayOfWeek = '星期五'
        break
      case 6:
        dayOfWeek = '星期六'
        break
    }
    return { day, dayOfWeek, time }
  }
}
