<template>
  <div
    v-if="configInfo"
    class="price-sign-container"
    :style="
      configInfo.backgroundImageUrl
        ? `background-image:url(${
            configInfo.bgImageKey === 'custom'
              ? configInfo.backgroundImageUrl
              : require('../../assets/images/priceSign/horizontal-bg-' +
                  configInfo.bgImageKey +
                  '.jpg')
          })
          })`
        : `background-color:white`
    "
  >
    <div class="top">
      <div class="hotel-name">
        <span
          v-if="configInfo.hotelNameShowType === 0"
          :style="`font-size:${$px2rem(
            configInfo.hotelNameSize + 'px'
          )};color:${configInfo.hotelNameColor}`"
          >{{ configInfo.hotelName }}
        </span>
        <img v-else :src="configInfo.hotelLogoUrl" alt="" />
      </div>
      <div class="time" :style="`color:${configInfo.hotelNameColor}`">
        {{ timeInfo.time }}<br />{{ timeInfo.day + ' ' + timeInfo.dayOfWeek }}
      </div>
    </div>
    <div :class="configInfo.notice ? 'center row' : 'center_no-bottom row'">
      <div
        class="room-types-prices"
        :style="`width:${
          configInfo.advertisingType === 3
            ? 100
            : 100 - configInfo.advertisingProportion * 100
        }%`"
      >
        <div class="room-types-prices-bg">
          <div
            :style="`height:${
              100 / (configInfo.displayRows + 1)
            }%;background-color:${configInfo.roomTypeBackgroundColor};opacity:${
              1 - configInfo.titleTransparency / 100
            }`"
          ></div>

          <div
            :style="`width: 100%;height:${
              100 - 100 / (configInfo.displayRows + 1)
            }%;background-color:${configInfo.roomTypeBackgroundColor};opacity:${
              1 - configInfo.contentTransparency / 100
            }`"
          ></div>
        </div>
        <div class="room-types-prices-content column">
          <div class="content row">
            <div
              class="room-types column"
              :style="`width:${configInfo.roomTypeNameWidthProportion * 100}%`"
            >
              <span
                class="header"
                :style="`height:${
                  100 / (configInfo.displayRows + 1)
                }%;font-size:${$px2rem(configInfo.titleSize + 'px')};;color:${
                  configInfo.titleColor
                };`"
                ref="roomTypeHeader"
              >
                房型
              </span>
              <div
                class="column"
                :style="`width: 100%;height:${
                  100 - 100 / (configInfo.displayRows + 1)
                }%;`"
              >
                <div
                  v-for="(item, index) in roomTypePrice.roomTypePages[
                    roomTypePageIndex
                  ]"
                  :key="index"
                  class="item"
                  :style="`height:${
                    100 / configInfo.displayRows
                  }%;font-size:${$px2rem(
                    configInfo.roomTypeSize + 'px'
                  )};color:${configInfo.roomTypeColor};`"
                >
                  <span class="name">{{ item.roomTypeName }}</span>
                  <span v-if="item.full" class="full">满房</span>
                </div>
              </div>
            </div>
            <div
              class="prices-container"
              :style="`width:${
                (1 - configInfo.roomTypeNameWidthProportion) * 100
              }%`"
            >
              <div class="prices row" ref="prices">
                <div
                  v-for="(
                    priceTypeInfo, priceTypeInfoIndex
                  ) in roomTypePrice.priceTypeInfoList"
                  :key="priceTypeInfoIndex"
                  class="column"
                  :style="
                    'width:' +
                    100 / roomTypePrice.priceTypeInfoList.length +
                    '%'
                  "
                >
                  <div
                    class="header"
                    :style="`height:${
                      100 / (configInfo.displayRows + 1)
                    }%;font-size:${$px2rem(
                      configInfo.titleSize + 'px'
                    )};color:${configInfo.titleColor};`"
                  >
                    {{ priceTypeInfo.name }}
                  </div>

                  <div
                    class="column"
                    :style="`width: 100%;height:${
                      100 - 100 / (configInfo.displayRows + 1)
                    }%;`"
                  >
                    <div
                      v-for="(item, priceIndex) in roomTypePrice.roomTypePages[
                        roomTypePageIndex
                      ]"
                      :key="priceIndex"
                      class="item"
                      :style="`height:${
                        100 / configInfo.displayRows
                      }%;font-size:${$px2rem(
                        configInfo.roomPriceSize + 'px'
                      )};color:${configInfo.roomPriceColor};`"
                    >
                      <div v-if="priceTypeInfo.type !== 1">
                        {{
                          priceTypeInfo.prices[
                            configInfo.displayRows * roomTypePageIndex +
                              priceIndex
                          ]
                        }}
                      </div>
                      <div v-else class="row" style="align-items: baseline">
                        <div>
                          {{
                            priceTypeInfo.prices[
                              configInfo.displayRows * roomTypePageIndex +
                                priceIndex
                            ] + ' 起'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="configInfo.advertisingType !== 3"
        class="carousel column"
        :style="`width:${configInfo.advertisingProportion * 100}%;`"
      >
        <!-- 显示自定义图片 -->
        <divider v-if="configInfo.advertisingType === 1" class="content">
          <img class="item" :src="configInfo.selfImageUrl" alt="" />
        </divider>
        <!-- 显示自定义视频 -->
        <div v-else-if="configInfo.advertisingType === 2" class="content">
          <video
            class="content"
            :src="configInfo.selfVideoUrl"
            autoplay
            playsinline
            muted
            loop
            ref="video"
          ></video>
        </div>
        <!-- 其余都显示房型图片 -->
        <a-carousel
          v-else
          class="content"
          autoplay
          :dots="false"
          :autoplaySpeed="configInfo.advertisingCycle * 1000"
        >
          <div class="item" v-for="item in roomTypes" :key="item.id">
            <img :src="item.cover" alt="" />
            <div class="name">
              <span>{{ item.roomTypeName }}</span>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div v-if="configInfo.notice" class="bottom" ref="noticeContainerRef">
      <div
        v-if="
          !noticeWidthInfo ||
          noticeWidthInfo.noticeWidth <= noticeWidthInfo.noticeContainerWidth
        "
        ref="noticeRef"
        class="text"
      >
        <span
          :style="'visibility:' + (noticeWidthInfo ? 'visible' : 'hidden')"
          >{{ configInfo.notice }}</span
        >
      </div>
      <div
        v-else
        class="text-scroll"
        :style="`animation-duration:${
          noticeWidthInfo.animationDuration
        }s;padding-left:${
          noticeWidthInfo.noticeWidth > noticeWidthInfo.noticeContainerWidth
            ? noticeWidthInfo.noticeContainerWidth
            : noticeWidthInfo.noticeWidth
        }px`"
      >
        <span>{{ configInfo.notice }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getBaseInfo, getConfig } from '@/api/priceSign'
import priceSignUtil from '@/utils/priceSignUtil'

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      hotelCode: null,
      // 酒店信息 会员等级信息 房型信息
      baseInfo: null,
      roomTypes: [],
      configInfo: null,
      timeInfo: null,
      // 房型价格
      roomTypePrice: null,
      room: 0,
      roomTypeWidth: 0,
      // 房型名长度
      roomTypeNameWidth: 0,
      // 每个价格长度
      priceItemWidth: 0,
      roomTypeItemHeight: 0,
      roomTypePageIndex: 0,
      // 价格滚动前后固定显示时间
      priceShowTime: 2500,
      // 房型滚动完成监听
      scrollPriceEventListener: null,
      // noticeNeedScroll: false
      noticeWidthInfo: null,
      // 时间刷新
      timeRefreshInterval: null,
      // 定时检查更新
      checkUpdateInterval: null,
      // 上一次请求的配置的json，用来和定时更新的信息进行比较看是否更新
      lastConfigInfoJson: null,
      // 定时刷新房型显示
      showRoomTypeInterval: null,
      // 背景音乐播放器
      backgroundMusicAudioContext: null,
      // 背景音乐
      backgroundMusicUrl: null
    }
  },
  created() {},

  mounted() {
    const hotelCode = this.$route.query.hotelCode
    if (hotelCode) {
      this.hotelCode = hotelCode
      this.init()
      return
    }
    // eslint-disable-next-line dot-notation
    window['initData'] = (hotelCode) => {
      this.hotelCode = hotelCode
      this.init()
    }
  },

  methods: {
    async init() {
      this.showTime()
      await this.initShowData()
      this.startCheckUpdate()
    },

    async initShowData() {
      const baseInfo = await this.getBaseInfo()
      this.baseInfo = baseInfo
      // 电子房价牌配置
      const configInfo = await this.getConfigInfo(baseInfo.hotelInfo.id)

      if (configInfo == null) {
        return
      }
      // // 显示类型，如果是横版跳转到横版
      // if (configInfo.screenType === 1) {
      //   this.$router.push({
      //     path: '/priceSignVertical',
      //     query: { hotelCode: this.hotelCode }
      //   })
      //   return
      // }
      // 房型信息
      const roomTypes = await priceSignUtil.getRoomTypes(baseInfo.hotelInfo.id)
      // 保存此处获取的房型信息和定时取的比较看是否更新了
      this.lastRoomTypesJson = JSON.stringify(roomTypes)
      // 保存此处获取的配置信息和定时取的比较看是否更新了
      this.lastConfigInfoJson = JSON.stringify(configInfo)
      const showInfo = priceSignUtil.getShowInfo(
        configInfo,
        roomTypes,
        baseInfo.userLevelConf
      )
      this.roomTypes = roomTypes
      this.roomTypePrice = showInfo.roomTypePrice
      this.configInfo = showInfo.configInfo
      this.showConfig()
    },

    /**
     * 开始定时检查更新
     */
    startCheckUpdate() {
      if (this.checkUpdateInterval) {
        clearInterval(this.checkUpdateInterval)
      }
      this.checkUpdateInterval = setInterval(() => {
        this.checkUpdate()
      }, 30 * 1000)
    },

    /**
     * 检查更新 当配置或者房型信息发生改变后视为更新了
     */
    async checkUpdate() {
      const configInfo = await this.getConfigInfo(this.baseInfo.hotelInfo.id)
      if (configInfo !== null) {
        // 显示类型，如果是横版跳转到横版
        if (configInfo.screenType === 1) {
          this.$router.push({
            path: '/priceSignVertical',
            query: { hotelCode: this.hotelCode }
          })
          return
        }
        const roomTypes = await priceSignUtil.getRoomTypes(
          this.baseInfo.hotelInfo.id
        )
        const roomTypesJson = JSON.stringify(roomTypes)
        const configInfoJson = JSON.stringify(configInfo)
        // 是否更新了
        const isUpdated =
          configInfoJson !== this.lastConfigInfoJson ||
          roomTypesJson !== this.lastRoomTypesJson
        if (isUpdated) {
          this.lastConfigInfoJson = configInfoJson
          this.lastRoomTypesJson = roomTypesJson
          const showInfo = priceSignUtil.getShowInfo(
            configInfo,
            roomTypes,
            this.baseInfo.userLevelConf
          )
          this.roomTypes = roomTypes
          this.roomTypePrice = showInfo.roomTypePrice
          this.configInfo = showInfo.configInfo
          this.showConfig()
        }
      }
    },

    /**
     * 显示配置
     */
    showConfig() {
      this.noticeWidthInfo = null
      setTimeout(() => {
        // this.playVideoConfig()
        this.showPagesNotScrollPriceInfo()
        this.initNoticeWidthInfo()
        this.playBackgroundMusic()
      }, 500)
    },

    /**
     * 获取酒店和会员级别信息
     */
    async getBaseInfo() {
      const data = await getBaseInfo(this.hotelCode)
      if (data.code !== 0) {
        return null
      }
      return data.data
    },

    /**
     * 获取配置信息
     */
    async getConfigInfo(hotelId) {
      const data = await getConfig(hotelId)
      if (data.code === 0) {
        return data.data
      }
      return null
    },

    /**
     * 显示时间
     */
    showTime() {
      this.timeInfo = priceSignUtil.getCurrentTime()
      if (this.timeRefreshInterval) {
        clearInterval(this.timeRefreshInterval)
      }
      this.timeRefreshInterval = setInterval(() => {
        this.timeInfo = priceSignUtil.getCurrentTime()
      }, 5 * 1000)
    },

    playVideoConfig() {
      if (this.configInfo.advertisingType === 2) {
        this.$refs.video.muted = false
        this.$refs.video.play()
      }
    },

    /**
     * 播放背景音乐
     */
    playBackgroundMusic() {
      if (!window.heizhuAndroid) {
        return
      }
      if (
        this.configInfo.backgroundMusicType === 1 &&
        this.configInfo.backgroundMusicUrl
      ) {
        // 播放音乐
        window.heizhuAndroid.startPlayMusic(this.configInfo.backgroundMusicUrl)
        return
      }
      // 停止播放音乐
      window.heizhuAndroid.stopPlayMusic()

      // window.WebViewJavascriptBridge("playBackgroundMusic")
      // const backgroundMusicUrl = this.configInfo.backgroundMusicUrl
      // // if (this.backgroundMusicUrl === backgroundMusicUrl) {
      // //   return
      // // }
      // console.log('backgroundMusicUrl1', backgroundMusicUrl)
      // this.backgroundMusicUrl = backgroundMusicUrl
      // if (!this.backgroundMusicAudioContext) {
      //   this.backgroundMusicAudioContext = new window.AudioContext()
      // }
      // // this.backgroundMusicAudioContext.stop()
      // const request = new XMLHttpRequest()
      // request.open('GET', backgroundMusicUrl, true)
      // request.responseType = 'arraybuffer'
      // request.onload = () => {
      //   this.backgroundMusicAudioContext.decodeAudioData(
      //     request.response,
      //     (buffer) => {
      //       console.log('backgroundMusicUrl', backgroundMusicUrl)
      //       const source = this.backgroundMusicAudioContext.createBufferSource()
      //       source.buffer = buffer
      //       source.loop = true
      //       source.connect(this.backgroundMusicAudioContext.destination)
      //       source.start()
      //     }
      //   )
      // }
      // request.send()
    },

    startShowPriceInfo() {
      if (this.showRoomTypeInterval) {
        clearInterval(this.showRoomTypeInterval)
      }
      const pricesView = this.$refs.prices
      pricesView.style.transition = ''
      pricesView.style.transform = 'none'

      // 有多少个价格类型
      const priceTypeInfoList = this.roomTypePrice.priceTypeInfoList
      // 一页显示多少个价格类型
      const pageShowPriceTypeCount = this.configInfo.pageShowPriceTypeCount
      // 如果一页能显示完，那么不滚动
      if (pageShowPriceTypeCount >= priceTypeInfoList.length) {
        this.showPagesNotScrollPriceInfo()
        return
      }
      this.showPagesScrollPriceInfo()
    },

    /**
     * 获取下一页
     */
    getNextRoomTypePageIndex() {
      // 动画结束时的操作
      let newRoomTypePageIndex = this.roomTypePageIndex + 1
      if (newRoomTypePageIndex >= this.roomTypePrice.roomTypePages.length) {
        newRoomTypePageIndex = 0
      }
      return newRoomTypePageIndex
    },

    /**
     * 不滚动房价
     */
    showPagesNotScrollPriceInfo() {
      if (this.roomTypePrice.roomTypePages.length > 1) {
        if (this.showRoomTypeInterval) {
          clearInterval(this.showRoomTypeInterval)
        }
        this.showRoomTypeInterval = setInterval(() => {
          // 需要显示的房型页
          const roomTypePageIndex = this.getNextRoomTypePageIndex()
          this.roomTypePageIndex = roomTypePageIndex
        }, this.configInfo.displayCycle * 1000)
      }
    },

    /**
     * 滚动房价
     */
    showPagesScrollPriceInfo() {
      this.addScrollPriceEventListener()
      this.doShowPagesScrollPriceInfo()
    },

    /**
     * 执行滚动
     */
    doShowPagesScrollPriceInfo() {
      setTimeout(() => {
        const configInfo = this.configInfo
        const priceTypeInfoList = this.roomTypePrice.priceTypeInfoList
        // 一页显示多少个价格类型
        const pageShowPriceTypeCount = configInfo.pageShowPriceTypeCount
        const translateX =
          (100 / pageShowPriceTypeCount) *
          (priceTypeInfoList.length - pageShowPriceTypeCount)
        // // 价格类型页数
        // const pricePageCount = priceTypeInfoList.length / pageShowPriceTypeCount
        // // 价格类型一页显示多久
        // const pricePageShowTime = 5
        // console.log('translateX', translateX)

        const pricesView = this.$refs.prices
        pricesView.style.transform = `translateX(-${translateX}%)`
        pricesView.style.transition = `transform ${configInfo.displayCycle}s linear`
      }, this.priceShowTime)
    },

    /**
     * 添加滚动完监听
     */
    addScrollPriceEventListener() {
      const pricesView = this.$refs.prices
      const event = 'transitionend'
      if (this.scrollPriceEventListener) {
        pricesView.removeEventListener(
          this.scrollPriceEventListener.event,
          this.scrollPriceEventListener.handler
        )
      }
      const handler = (event) => {
        if (event.propertyName.includes('transform')) {
          //  动画结束显示一定时间后显示下一页
          setTimeout(() => {
            // 需要显示的房型页
            const roomTypePageIndex = this.getNextRoomTypePageIndex()
            if (this.roomTypePageIndex !== roomTypePageIndex) {
              this.roomTypePageIndex = roomTypePageIndex
            }
            pricesView.style.transition = ''
            pricesView.style.transform = 'none'
            this.doShowPagesScrollPriceInfo()
          }, this.priceShowTime)
        }
      }
      this.scrollPriceEventListener = {
        event,
        handler
      }
      pricesView.addEventListener(event, handler, false)
    },

    /**
     * 添加公告提醒
     */
    initNoticeWidthInfo() {
      const noticeContainerView = this.$refs.noticeContainerRef
      if (!noticeContainerView) {
        return
      }
      const noticeView = this.$refs.noticeRef
      const noticeWidth = noticeView.offsetWidth
      const noticeContainerWidth = noticeContainerView.offsetWidth
      if (noticeWidth > noticeContainerWidth) {
        // 满一页动画时间
        const pageTime = 20
        const animationDuration =
          (noticeWidth / noticeContainerWidth) * pageTime
        this.noticeWidthInfo = {
          noticeWidth,
          noticeContainerWidth,
          animationDuration
        }
        return
      }
      this.noticeWidthInfo = {
        noticeWidth,
        noticeContainerWidth
      }
    }
  },

  beforeDestroy() {
    if (this.checkUpdateInterval) {
      clearInterval(this.checkUpdateInterval)
    }
    if (this.timeRefreshInterval) {
      clearInterval(this.timeRefreshInterval)
    }
    if (this.showRoomTypeInterval) {
      clearInterval(this.showRoomTypeInterval)
    }
  }
}
</script>

<style lang="less" scoped>
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.price-sign-container {
  width: 100%;
  height: 100vh;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center; /* 确保图像居中 */
}

.top {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  .hotel-name {
    height: 100%;
    display: flex;
    align-items: center;
    width: 65%;
    span {
      font-size: 25px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      overflow: hidden;
      height: 80%;
      object-fit: cover;
    }
  }

  .hotel-logo {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: fixed;
    top: 0;
    left: 0;
  }

  .time {
    font-size: 14px;
    text-align: right;
    height: 42px;
    width: 35%;
  }
}

.center {
  height: calc(100vh - 80px - 50px);
  padding: 0 20px 20px;
}

.center_no-bottom {
  height: calc(100vh - 80px);
  padding: 0 20px 20px;
}

.room-types-prices {
  position: relative;
  height: 100%;
  .room-types-prices-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    div {
      width: 100%;
    }
  }
  .room-types-prices-content {
    overflow-x: hidden;
    font-size: 19px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    div,
    span {
      flex-shrink: 0;
    }

    .header {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .content {
      width: 100%;
      height: 100%;
      .room-types {
        height: 100%;

        .header {
          padding-left: 20px;
        }

        .item {
          padding-left: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .name {
            max-width: calc(100% - 46px);
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏溢出的内容 */
            text-overflow: ellipsis; /* 显示省略号 */
          }

          .full {
            width: 40px;
            text-align: center;
            background-color: #ff0000;
            font-size: 15px;
            border-radius: 5px;
            margin-left: 5px;
            color: white;
          }
        }

        @keyframes priceScroll {
          0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }
          100% {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
          }
        }
      }
      .prices-container {
        overflow-x: hidden;
        width: 100%;
        height: 100%;

        .prices {
          /* // -webkit-animation: 10s priceScroll linear normal;
          // animation: 10s priceScroll linear normal; */
          width: 100%;
          height: 100%;
          text-align: center;

          .header {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 19px;
          }
        }
      }
    }
  }
}
.carousel {
  width: 100%;
  padding-left: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    width: 100%;
    height: 100%;
    .item {
      width: 100%;
      height: 100%;
      position: relative;

      .name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: white;
          font-size: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          /*限制文本行数*/
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box; /* 使用弹性盒子布局模型 */
        }
      }
    }
  }
}

::v-deep .ant-carousel div {
  height: 100%;
}

/* // .video {
//   width: 100%;
//   height: calc(100% - 42px);
// } */

.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  line-height: 50px;
  background-color: #000000;
  font-weight: bold;
  font-size: 25px;
  color: #ff0000;
  text-align: center;

  .text {
    white-space: nowrap;
    display: inline-block;
  }

  @keyframes noticeScroll {
    // 0% {
    //   -webkit-transform: translate3d(0, 0, 0);
    //   transform: translate3d(0, 0, 0);
    // }
    // 100% {
    //   -webkit-transform: translate3d(0, -100%, 0);
    //   transform: translate3d(0, -100%, 0);
    // }
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  .text-scroll {
    white-space: nowrap;
    display: inline-block;
    -webkit-animation: 20s noticeScroll linear infinite normal;
    animation: 20s noticeScroll linear infinite normal;
  }
}
</style>
