import req from '../utils/request.js'
const path = '/api/pms'

/**
 * 电子房价牌登录
 */
export function priceSignLogin(hotelCode) {
  return req.get(`${path}/cbh/hotelInfo/priceSignLogin?hotelCode=${hotelCode}`)
}

/**
 * 获取酒店信息和会员等级信息
 */
export function getBaseInfo(hotelCode) {
  return req.get(
    `${path}/cbh/priceSignConf/getHotelInfo?hotelCode=${hotelCode}`
  )
}

/**
 * 获取酒店房型信息
 */
export function getRoomTypes(hotelId) {
  return req.get(
    `${path}/cbh/priceSignConf/queryRoomTypesInfo?hotelId=${hotelId}`
  )
}

/**
 * 获取电子房价牌配置
 */
export function getConfig(hotelId) {
  return req.get(`${path}/cbh/priceSignConf/info/${hotelId}`)
}
