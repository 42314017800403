<template>
  <div>
    <!--  横版 -->
    <div
      v-if="type == 1 && bgc"
      class="content"
      :style="
        bgc === 'black'
          ? 'background-color: rgba(19, 29, 29, 1)'
          : {
              'background-image': `url(${require(`../assets/${bgc}.png`)}`
            }
      "
    >
      <div class="contatiner">
        <div class="left">
          <div class="title" :class="bgc === 'orange' ? '' : 'white-word'">
            {{ showInfo.hotelName }}
          </div>
          <div class="theader" :class="headerColor">
            <div style="width: 56%; text-align: left">房型</div>
            <div style="width: 22%; text-align: right">今日价</div>
            <div style="width: 22%; text-align: right">会员价</div>
          </div>
          <div class="list">
            <div
              :class="
                showInfo && showInfo.roomTypes && showInfo.roomTypes.length >= 6
                  ? 'rowup'
                  : ''
              "
            >
              <ul class="roomUl">
                <li
                  v-for="item in showInfo.roomTypes"
                  :key="item.roomTypeId"
                  :class="liColor"
                >
                  <div style="width: 56%; text-align: left">
                    {{ item.roomTypeName }}
                  </div>
                  <div style="width: 22%; text-align: right">
                    {{ item.price }}
                  </div>
                  <div
                    class="memberPrice"
                    :class="wordColor"
                    style="width: 22%; text-align: right"
                  >
                    {{ item.memberPrice }}
                  </div>
                </li>
              </ul>
              <ul
                class="roomUl"
                v-if="
                  showInfo &&
                  showInfo.roomTypes &&
                  showInfo.roomTypes.length >= 6
                "
              >
                <li
                  v-for="item in showInfo.roomTypes"
                  :key="item.roomTypeId"
                  :class="liColor"
                >
                  <div style="width: 56%; text-align: left">
                    {{ item.roomTypeName }}
                  </div>
                  <div style="width: 22%; text-align: right">
                    {{ item.price }}
                  </div>
                  <div
                    class="memberPrice"
                    :class="wordColor"
                    style="width: 22%; text-align: right"
                  >
                    {{ item.memberPrice }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <div
            class="right-header"
            :class="bgc === 'orange' ? '' : 'white-word'"
          >
            <div>
              <div class="date">
                {{ currentDate }} {{ currentTime }} {{ currentDay }}
              </div>
            </div>
            <div></div>
          </div>
          <!-- <img style="width: 100%" src="../assets/images/1.jpg" alt="" /> -->
          <a-carousel autoplay style="width: 100%">
            <img
              v-for="item in showInfo.roomTypes"
              :key="item.roomTypeId"
              style="width: 100%; height: 100%; object-fit: cover"
              :src="item.image"
              alt=""
            />
          </a-carousel>
          <div class="footer" :class="footerColor">
            订房热线：{{ showInfo.phone }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { priceSignLogin } from '@/api'

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      type: 1,
      currentDate: '',
      currentTime: '',
      currentDay: '',
      showInfo: '',
      bgc: '',
      wordColor: '',
      headerColor: '',
      liColor: '',
      footerColor: '',
      hotelCode: '',
      msg: '',
      limitConnect: 3,
      interval: '',
      test: ''
    }
  },
  mounted() {
    // eslint-disable-next-line dot-notation
    window['initData'] = (code, color, data) => {
      this.bgc = color
      this.wordColor = `${this.bgc}-word`
      this.headerColor = `${this.bgc}-header`
      this.liColor = `${this.bgc}-li`
      this.footerColor = `${this.bgc}-footer`
      this.msg = '请求开始'
      this.hotelCode = code
      // this.getInfo
      this.showInfo = JSON.parse(data)
      // this.showInfo = data

      this.openSocket()
    }
    const num = document.body.clientWidth / document.body.clientHeight
    if (num >= 1) {
      this.type = 1
    } else {
      this.type = 2
    }
  },
  created() {
    this.getCurrentTime()
    setInterval(() => {
      this.getCurrentTime()
    })
  },
  methods: {
    getCurrentTime() {
      const data = new Date().getTime()
      this.currentDate = dayjs(data).format('YYYY年MM月DD日')
      this.currentTime = dayjs(data).format('HH:mm')
      const num = dayjs(data).day()
      switch (num) {
        case 0:
          this.currentDay = '星期天'
          break
        case 1:
          this.currentDay = '星期一'
          break
        case 2:
          this.currentDay = '星期二'
          break
        case 3:
          this.currentDay = '星期三'
          break
        case 4:
          this.currentDay = '星期四'
          break
        case 5:
          this.currentDay = '星期五'
          break
        case 6:
          this.currentDay = '星期六'
          break
      }
    },
    async getInfo(success, error) {
      const data = await priceSignLogin(this.hotelCode)

      if (data.code === 0) {
        this.showInfo = data.data
      }
      this.falg = false
      const xhrRequest = new XMLHttpRequest()
      xhrRequest.open(
        'get',
        `https://api-ims.charmplus.cn/pms/cbh/hotelInfo/priceSignLogin?hotelCode=${this.hotelCode}`,
        true
      )
      xhrRequest.send()
      xhrRequest.onreadystatechange = function () {
        if (xhrRequest.status === 200 && xhrRequest.responseText) {
          const data = JSON.parse(
            xhrRequest.responseText.replace(/\n/g, '\\n').replace(/\r/g, '\\r')
          )
          if (this.flag) return false
          this.flag = true
          success(data)
        } else {
          //   console.log(xhrRequest.responseText)
          error(xhrRequest)
        }
      }
    },
    openSocket() {
      // let socket = new WebSocket(`ws://localhost:8081/cbh-admin/priceSign/123456`)
      let webUrl = 'ws://localhost:8081/cbh-admin/priceSign/123456'
      if (process.env.VUE_APP_MODE === 'development') {
        webUrl = 'ws://localhost:8081/cbh-admin/priceSign/123456'
      }
      if (
        process.env.VUE_APP_MODE === 'locals' ||
        process.env.VUE_APP_MODE === 'test'
      ) {
        webUrl = 'ws://192.168.3.196:28080/cbh-admin/priceSign/123456'
      }
      if (process.env.VUE_APP_MODE === 'production') {
        webUrl = `wss://api-ims.charmplus.cn/pms/ws/notice/priceSign/${this.hotelCode}`
      }
      console.log(webUrl)
      const socket = new WebSocket(webUrl)

      socket.onopen = function () {
        this.limitConnect = 3 // Reset
        this.interval = window.setInterval(function () {
          socket.send(JSON.stringify({ type: 'ping' })) // 连接保持
        }, 2000 * 10)
      }

      socket.onmessage = function (msg) {
        const data = JSON.parse(msg.data)
        console.log(data)
        this.showInfo.roomTypes = data
      }

      socket.onclose = function (e) {
        console.error('Connection closed: ' + e.code + '-' + e.reason)
        this.reopenSocket()
      }

      socket.onerror = function () {}
    },
    reopenSocket() {
      window.clearInterval(this.interval)
      if (this.limitConnect > 0) {
        console.log('Reconnect to server...')
        window.setTimeout(function () {
          this.openSocket()
        }, 1500)
      }
    }
  }
}

// function test (e) {
//   this.currentDay = '265662'
// }
// window.test = test
</script>

<style lang="less" scoped>
.ant-carousel .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.date {
  font-size: 28px;
  line-height: 100px;
}
.ant-carousel {
  height: calc(100% - 215px);
}
/deep/.ant-carousel > * {
  height: 100%;
}
/deep/.slick-list {
  height: 100%;
}
/deep/.slick-track {
  height: 100%;
}
.ant-carousel /deep/.slick-slide {
  text-align: center;
  line-height: 160px;
  // background: #364d79;
  overflow: hidden;
}
/deep/.slick-slide div {
  height: 100%;
}
.ant-carousel /deep/.slick-dots {
  display: none !important;
}

.ant-carousel /deep/.slick-slide h3 {
  color: #fff;
}
.black-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #ffc935 !important;
}
.orange-footer {
  background-color: #f18d00 !important;
  color: #f25828 !important;
}
.blue-footer {
  background-color: rgba(52, 120, 246, 0.1) !important;
  color: #ffff00 !important;
}
.deep-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #ffc935 !important;
}
.black-li {
  background-color: rgba(255, 255, 255, 0.08) !important;
}
.orange-li {
  background-color: #f18d0030 !important;
}
.blue-li {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.deep-li {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.black-header {
  background-color: #ffffff47 !important;
}
.orange-header {
  background-color: #f25828 !important;
}
.blue-header {
  background-color: #149eff !important;
}
.deep-header {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.black-word {
  color: #ffc935 !important;
}
.blue-word {
  color: #ffff00 !important;
}
.orange-word {
  color: #f25828 !important;
}
.deep-word {
  color: #ffc935 !important;
}
.white-word {
  color: #fff !important;
}
.black {
  background-color: #131d1d !important;
}

.blue {
  background-color: #4ebeff !important;
}

.orange {
  background-color: #f25828 !important;
}

@keyframes scrollTop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}
.list {
  // height: 37.5rem;
  height: calc(100% - 200px);
  overflow: hidden;
}

.rowup {
  -webkit-animation: 60s scrollTop linear infinite normal;
  animation: 60s scrollTop linear infinite normal;
}
.w50 {
  width: 50%;
}
.w50 {
  width: 50%;
}
.theader {
  z-index: 111;
  background-color: #f25828;
  font-size: 33.6px;
  padding: 5px 20px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-weight: 700;
}
.roomUl {
  padding: 0 !important;
  li {
    font-size: 33.6px;
    padding: 5px 20px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #f18d0030;

    .memberPrice {
      color: #f25828;
    }
  }
}

.content {
  width: 100%;
  height: 100vh;
  // background: url("../assets/signBg.png");

  .contatiner {
    height: 100%;
    padding: 60px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 53%;
      height: 100%;
      //   background-color: aqua;

      .title {
        font-size: 56px;
        text-align: left;
        line-height: 100px;
      }
    }
  }
}
.right {
  width: 45%;
  height: 100%;
  //   background-color: burlywood;

  .right-header {
    width: 100%;
    font-size: 28px;
    color: #f25828;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 0.9375rem;
  }

  .footer {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #f25828;
    background-color: #f18d00;
    margin-top: 20px;
    font-size: 35px;
    line-height: 80px;
    font-weight: 700;
  }
}
</style>
