var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.configInfo)?_c('div',{staticClass:"price-sign-container",style:(_vm.configInfo.backgroundImageUrl
        ? `background-image:url(${
            _vm.configInfo.bgImageKey === 'custom'
              ? _vm.configInfo.backgroundImageUrl
              : require('../../assets/images/priceSign/vertical-bg-' +
                  _vm.configInfo.bgImageKey +
                  '.jpg')
          })
          })`
        : `background-color:white`)},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"hotel-name"},[(_vm.configInfo.hotelNameShowType === 0)?_c('span',{style:(`font-size:${_vm.$px2rem(
            _vm.configInfo.hotelNameSize + 'px'
          )};color:${_vm.configInfo.hotelNameColor}`)},[_vm._v(_vm._s(_vm.configInfo.hotelName)+" ")]):_c('img',{attrs:{"src":_vm.configInfo.hotelLogoUrl,"alt":""}})]),_c('div',{staticClass:"time",style:(`color:${_vm.configInfo.hotelNameColor}`)},[_vm._v(" "+_vm._s(_vm.timeInfo.time)),_c('br'),_vm._v(_vm._s(_vm.timeInfo.day + ' ' + _vm.timeInfo.dayOfWeek)+" ")])]),_c('div',{class:_vm.configInfo.notice ? 'center' : 'center_no-bottom'},[_c('div',{staticClass:"room-types-prices",style:(`height:${
          _vm.configInfo.advertisingType === 3
            ? 100
            : 100 - _vm.configInfo.advertisingProportion * 100
        }%`)},[_c('div',{staticClass:"room-types-prices-bg"},[_c('div',{style:(`height:${
              100 / (_vm.configInfo.displayRows + 1)
            }%;background-color:${_vm.configInfo.roomTypeBackgroundColor};opacity:${
              1 - _vm.configInfo.titleTransparency / 100
            }`)}),_c('div',{style:(`width: 100%;height:${
              100 - 100 / (_vm.configInfo.displayRows + 1)
            }%;background-color:${_vm.configInfo.roomTypeBackgroundColor};opacity:${
              1 - _vm.configInfo.contentTransparency / 100
            }`)})]),_c('div',{staticClass:"room-types-prices-content column"},[_c('div',{staticClass:"content row"},[_c('div',{staticClass:"room-types column",style:(`width:${_vm.configInfo.roomTypeNameWidthProportion * 100}%`)},[_c('span',{ref:"roomTypeHeader",staticClass:"header",style:(`height:${
                  100 / (_vm.configInfo.displayRows + 1)
                }%;font-size:${_vm.$px2rem(_vm.configInfo.titleSize + 'px')};;color:${
                  _vm.configInfo.titleColor
                };`)},[_vm._v(" 房型 ")]),_c('div',{staticClass:"column",style:(`width: 100%;height:${
                  100 - 100 / (_vm.configInfo.displayRows + 1)
                }%;`)},_vm._l((_vm.roomTypePrice.roomTypePages[
                    _vm.roomTypePageIndex
                  ]),function(item,index){return _c('div',{key:index,staticClass:"item",style:(`height:${
                    100 / _vm.configInfo.displayRows
                  }%;font-size:${_vm.$px2rem(
                    _vm.configInfo.roomTypeSize + 'px'
                  )};color:${_vm.configInfo.roomTypeColor};`)},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.roomTypeName))]),(item.full)?_c('span',{staticClass:"full"},[_vm._v("满房")]):_vm._e()])}),0)]),_c('div',{staticClass:"prices-container",style:(`width:${
                (1 - _vm.configInfo.roomTypeNameWidthProportion) * 100
              }%`)},[_c('div',{ref:"prices",staticClass:"prices row"},_vm._l((_vm.roomTypePrice.priceTypeInfoList),function(priceTypeInfo,priceTypeInfoIndex){return _c('div',{key:priceTypeInfoIndex,staticClass:"column",style:('width:' +
                    100 / _vm.roomTypePrice.priceTypeInfoList.length +
                    '%')},[_c('div',{staticClass:"header",style:(`height:${
                      100 / (_vm.configInfo.displayRows + 1)
                    }%;font-size:${_vm.$px2rem(
                      _vm.configInfo.titleSize + 'px'
                    )};color:${_vm.configInfo.titleColor};`)},[_vm._v(" "+_vm._s(priceTypeInfo.name)+" ")]),_c('div',{staticClass:"column",style:(`width: 100%;height:${
                      100 - 100 / (_vm.configInfo.displayRows + 1)
                    }%;`)},_vm._l((_vm.roomTypePrice.roomTypePages[
                        _vm.roomTypePageIndex
                      ]),function(item,priceIndex){return _c('div',{key:priceIndex,staticClass:"item",style:(`height:${
                        100 / _vm.configInfo.displayRows
                      }%;font-size:${_vm.$px2rem(
                        _vm.configInfo.roomPriceSize + 'px'
                      )};color:${_vm.configInfo.roomPriceColor};`)},[(priceTypeInfo.type !== 1)?_c('div',[_vm._v(" "+_vm._s(priceTypeInfo.prices[ _vm.configInfo.displayRows * _vm.roomTypePageIndex + priceIndex ])+" ")]):_c('div',{staticClass:"row",staticStyle:{"align-items":"baseline"}},[_c('div',[_vm._v(" "+_vm._s(priceTypeInfo.prices[ _vm.configInfo.displayRows * _vm.roomTypePageIndex + priceIndex ] + ' 起')+" ")])])])}),0)])}),0)])])])]),(_vm.configInfo.advertisingType !== 3)?_c('div',{staticClass:"carousel column",style:(`height:${_vm.configInfo.advertisingProportion * 100}%;`)},[(_vm.configInfo.advertisingType === 1)?_c('divider',{staticClass:"content"},[_c('img',{staticClass:"item",attrs:{"src":_vm.configInfo.selfImageUrl,"alt":""}})]):(_vm.configInfo.advertisingType === 2)?_c('video',{staticClass:"content",attrs:{"src":_vm.configInfo.selfVideoUrl,"autoplay":"","playsinline":"","loop":""}}):_c('a-carousel',{staticClass:"content",attrs:{"autoplay":"","dots":false,"autoplaySpeed":_vm.configInfo.advertisingCycle * 1000}},_vm._l((_vm.roomTypes),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('img',{attrs:{"src":item.cover,"alt":""}}),_c('div',{staticClass:"name"},[_c('span',[_vm._v(_vm._s(item.roomTypeName))])])])}),0)],1):_vm._e()]),(_vm.configInfo.notice)?_c('div',{ref:"noticeContainerRef",staticClass:"bottom"},[(
          !_vm.noticeWidthInfo ||
          _vm.noticeWidthInfo.noticeWidth <= _vm.noticeWidthInfo.noticeContainerWidth
        )?_c('div',{ref:"noticeRef",staticClass:"text"},[_c('span',{style:('visibility:' + (_vm.noticeWidthInfo ? 'visible' : 'hidden'))},[_vm._v(_vm._s(_vm.configInfo.notice))])]):_c('div',{staticClass:"text-scroll",style:(`animation-duration:${
          _vm.noticeWidthInfo.animationDuration
        }s;padding-left:${
          _vm.noticeWidthInfo.noticeWidth > _vm.noticeWidthInfo.noticeContainerWidth
            ? _vm.noticeWidthInfo.noticeContainerWidth
            : _vm.noticeWidthInfo.noticeWidth
        }px`)},[_c('span',[_vm._v(_vm._s(_vm.configInfo.notice)+" ")])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }